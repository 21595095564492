import { createApp, h } from 'vue';
// import { GlobalProperties } from 'o365-vue';

let pendingPromise:Promise<any>|null = null;

export async function handleLogin(pOptions = null) {
    if (pendingPromise) {
        return pendingPromise;
    } else {        
        pendingPromise = new Promise(async (res) => {
            // let loginHandlerRef:any = null;
            const { default: LoginHandler } = await import('./App.vue');
            const { autoAnimatePlugin } = await import('auto-animate');
            const app = await createApp({
                setup() {
                    return () => h(LoginHandler, {
                        initialOptions: pOptions,
                        ref: 'loginHandler', 'onClose': async () => {
                            await app.unmount();
                            appNode.remove();
                            pendingPromise = null;
                            res(null);
                        }
                    });
                },
                mounted() {
                    // loginHandlerRef = this.$refs.loginHandler
                }
            });
            app.use(autoAnimatePlugin);
            app.config.globalProperties.$t = (value: string) => value;

            const appNode = document.createElement('div');
            appNode.id = 'loginDialog'
            appNode.style.zIndex = '2000';
            appNode.style.width = '100%';
            appNode.style.height = '100%';
            appNode.style.position = 'fixed';
            appNode.style.top = '0';
            appNode.style.backgroundColor = 'rgba(200,200,200,0.6)';
            document.body.append(appNode);
            window.requestAnimationFrame(() => {
                app.mount('#loginDialog');
            });
        });

        return pendingPromise;
    }
}
